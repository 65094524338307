<template>
  <div class="select-empresa__container">
    <loading :show="loading"></loading>

    <div class="select-empresa__container__item-close">
      <font-awesome-icon :icon="['fa', 'times']" size="2x" @click="close()" />
    </div>

    <div class="select-empresa__container__item-content">
      <div class="select-empresa__container__content">
        <span class="select-empresa__container__content__title"
          >Selecione a empresa desejada:</span
        >

        <ul>
          <li
            class="select-empresa__container__content__item"
            :class="
              empresa.id_empresa === currentIdEmpresa
                ? 'select-empresa__container__content__item--active'
                : null
            "
            v-for="(empresa, index) of listEmpresa"
            :key="index"
            @click="loginEmpresa(empresa.id_empresa)"
          >
            <div class="radio"></div>
            <span>
              {{ empresa.nome }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import service from './../../services';

import {
  getListEmpresas,
  getIdUsuarioInitialLogin,
  saveIdEmpresa,
} from './../../services/multiple-empresa';
import loading from './../../shared/loading.vue';

export default {
  components: {
    loading,
  },
  data() {
    return {
      loading: true,
      listEmpresa: [],
      currentIdEmpresa: null,
    };
  },
  methods: {
    getCurrentIdEmpresa() {
      this.currentIdEmpresa = +localStorage.getItem('idEmpresa') || null;
    },
    close() {
      this.$router.replace(`/dashboard`);
    },
    goDashboard() {
      this.loading = true;
      this.$router.replace(`/dashboard`);
    },
    async loginEmpresa(idEmpresaSelected) {
      if (idEmpresaSelected === this.currentIdEmpresa) {
        this.goDashboard();
        return;
      }

      const idUsuarioIntiialLogin = getIdUsuarioInitialLogin();

      if (!idUsuarioIntiialLogin) {
        this.goDashboard();
        return;
      }

      this.loading = true;

      try {
        const postObj = {
          idUsuario: idUsuarioIntiialLogin,
          idEmpresa: idEmpresaSelected,
        };

        const result = await service.post(
          'Login',
          'muda-empresa-usuario',
          postObj,
        );

        const { token } = result.data;

        if (token) {
          localStorage.setItem('user-token', token);
          saveIdEmpresa(token);
          this.goDashboard();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    document.body.className = 'select-empresa';
    this.listEmpresa = getListEmpresas();
    this.getCurrentIdEmpresa();
    this.loading = false;
  },
};
</script>

<style>
.select-empresa {
  background: #f8f8f8;
  height: 100%;
}

.select-empresa #app,
.select-empresa main {
  height: 100%;
}

.select-empresa .select-empresa__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__item-close {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 16px;
  cursor: pointer;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__item-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-empresa .select-empresa__container .select-empresa__container__content {
  max-width: 300px;
  margin-top: -80px;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__content__title {
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin-bottom: 16px;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__content
  ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__content__item {
  background: #e6e6e6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  height: 48px;
  padding: 10px;
  gap: 8px;
  cursor: pointer;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__content__item
  .radio {
  height: 16px;
  width: 16px;
  background: #a5a5a5;
  border-radius: 50%;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__content__item
  span {
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__content__item--active {
  background: #cce5ff;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__content__item--active
  .radio {
  border: 1px solid #078fcf;
  background: none;
}

.select-empresa
  .select-empresa__container
  .select-empresa__container__content__item--active
  .radio::after {
  content: '';
  display: block;
  position: relative;
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #078fcf;
}
</style>
