import jwt from 'jsonwebtoken';

export function saveListEmpresas(value) {
  localStorage.setItem('listEmpresas', JSON.stringify(value));
}

export function getListEmpresas() {
  const listEmprestaString = localStorage.getItem('listEmpresas');
  if (listEmprestaString) {
    return JSON.parse(listEmprestaString);
  }

  return [];
}

export function saveIdEmpresaDefault(value) {
  localStorage.setItem('idEmpresaDefault', JSON.stringify(value));
}

export function getIdEmpresaDefault() {
  return localStorage.getItem('idEmpresaDefault');
}

export function saveIdEmpresa(token) {
  const decoded = jwt.decode(token);
  localStorage.setItem('idEmpresa', decoded.id_empresa);
}

export function saveIdUsuarioInitialLogin(token) {
  const decoded = jwt.decode(token);

  if (decoded && decoded.id_usuario) {
    localStorage.setItem('idUsuarioInitialLogin', decoded.id_usuario);
  }
}

export function getIdUsuarioInitialLogin() {
  return localStorage.getItem('idUsuarioInitialLogin') || null;
}

export function getCurrentNameEmpresa() {
  const listEmpresa = getListEmpresas();
  const idEmpresa = +localStorage.getItem('idEmpresa');

  if (listEmpresa && idEmpresa) {
    const empresa = listEmpresa.find((x) => x.id_empresa === idEmpresa);
    if (empresa) {
      return empresa.nome;
    }
  }

  return '';
}
